import React, { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import ScrollToTop from "../utils/ScrollToTop";
import InterceptRoute from "../utils/InterceptRoute";
import Loader from "../utils/Loader/Index";

/**
 * @BOC
 * @author Maanik Arya
 * @date 13-Dec-2022
 * @use_of_code importing the ChangePayment and ExtraMessages, for providing route.
 */
import ChangePaymentpage from "../views/ThankyouPage/ChangePaymentpage";
import ExtraMessages from "../views/ThankyouPage/ExtraMessages";
/**
 * @EOC
 */

const Home = loadable(() => import("../views/Home/Home"), {
  fallback: <Loader />,
});
const Checkout = loadable(() => import("../views/checkout/Checkout"), {
  fallback: "",
});
const ProductCatalog = loadable(
  () => import("../views/product-catalog/ProductCatalog"),
  {
    fallback: "",
  }
);
const ProductDetails = loadable(
  () => import("../views/product-details/ProductDetails"),
  {
    fallback: "",
  }
);
const AccountProfile = loadable(() => import("../views/Account/Profile"), {
  fallback: "",
});
const OrderList = loadable(() => import("../views/Account/OrderList"), {
  fallback: "",
});

const Address = loadable(() => import("../views/Account/Address"), {
  fallback: "",
});

const GiftCardDetails = loadable(
  () => import("../views/Account/GiftCardDetails"),
  {
    fallback: "",
  }
);

const Rewards = loadable(() => import("../views/Account/Rewards"), {
  fallback: "",
});

const OrderDetailsPage = loadable(
  () => import("../components/Account/Orders/OrderDetailsPage"),
  {
    fallback: "",
  }
);

const TrackOrdersForm = loadable(
  () => import("../views/TrackOrders/TrackOrdersForm"),
  {
    fallback: "",
  }
);

const WishlistPage = loadable(() => import("../views/Wishlist/WishlistPage"), {
  fallback: "",
});

const FriendWishlistPage = loadable(
  () => import("../views/Wishlist/FriendWishlistPage"),
  {
    fallback: "",
  }
);
const AboutUs = loadable(() => import("../views/CmsPages/AboutUs"), {
  fallback: "",
});
const Career = loadable(() => import("../views/CmsPages/Career"), {
  fallback: "",
});
const Faq = loadable(() => import("../views/CmsPages/Faq"), {
  fallback: "",
});
const PaymentMethods = loadable(
  () => import("../views/CmsPages/PaymentMethods"),
  {
    fallback: "",
  }
);
const PrivacyPolicy = loadable(
  () => import("../views/CmsPages/PrivacyPolicy"),
  {
    fallback: "",
  }
);
const ReturnAndExchange = loadable(
  () => import("../views/CmsPages/ReturnAndExchange"),
  {
    fallback: "",
  }
);
const ShippingInformation = loadable(
  () => import("../views/CmsPages/ShippingInformation"),
  {
    fallback: "",
  }
);
const TermsAndConditions = loadable(
  () => import("../views/CmsPages/TermsAndConditions"),
  {
    fallback: "",
  }
);
const Testimonials = loadable(() => import("../views/CmsPages/Testimonials"), {
  fallback: "",
});
const Magazine = loadable(() => import("../views/CmsPages/Magazine"), {
  fallback: "",
});
const PressAndRelease = loadable(
  () => import("../views/CmsPages/PressAndRelease"),
  {
    fallback: "",
  }
);
const News = loadable(() => import("../views/CmsPages/News"), {
  fallback: "",
});
const DealOfTheDay = loadable(
  () => import("../views/product-catalog/DealOfTheDay"),
  {
    fallback: "",
  }
);
const ThankyouPage = loadable(
  () => import("../views/ThankyouPage/ThankyouPage"),
  {
    fallback: "",
  }
);
const PayuResponse = loadable(
  () => import("../views/ThankyouPage/PayuResponse"),
  {
    fallback: "",
  }
);
const GiftCardPage = loadable(() => import("../views/GiftCard/GiftCardPage"), {
  fallback: "",
});
const GiftCardDetailPage = loadable(
  () => import("../views/GiftCard/GiftCardDetailPage"),
  {
    fallback: "",
  }
);
const AccountsMainPage = loadable(
  () => import("../views/Account/AccountsMainPage"),
  {
    fallback: "",
  }
);
const ContactUsForm = loadable(
  () => import("../views/CmsPages/ContactUsForm"),
  {
    fallback: "",
  }
);
const ResetPassword = loadable(() => import("../views/Account/ResetPassword"), {
  fallback: "",
});
const Atelier = loadable(() => import("../views/CmsPages/Atelier"), {
  fallback: "",
});

const BlogList = loadable(() => import("../views/Blog/BlogList"), {
  fallback: "",
});

const Blog = loadable(() => import("../views/Blog/Blog"), {
  fallback: "",
});

const Designers = loadable(() => import("../views/product-catalog/Designers"), {
  fallback: "",
});

const Authentic = loadable(() => import("../views/Authentic/Authentic"), {
  fallback: "",
});

const NewsletterThankyouPage = loadable(
  () => import("../components/Footer/NewsletterThankyouPage"),
  {
    fallback: "",
  }
);

/**

   * BOC
   * Task #157716 Order Not Placed
   * author Maanik Arya
   * date 20-01-2025
   * use_of_code
   * We have created a new route which is used to the redirect Klarna and Affirm payments to a new component called stripe-response.
   * The stripe-response component is responsible for verifying whether the payment was successful.
   * If the payment is successful, the appCreateOrder API is called to place the order, and the user is redirected directly to the "Thank You" page without invoking the appUpdatePaymentStatus or appGetLastOrder APIs.
   * If the payment fails, the customer is redirected back to the payment page to try again.
   * This update addresses and prevents issues related to orders not being captured properly.
   */
const StripeResponse = loadable(
  () => import("../views/ThankyouPage/StripeResponse"),
  {
    fallback: "",
  }
)
/**
 * EOC
 */




function Navigation() {
  return (
    <>
      <Fragment>
        {/* Routes */}
        <InterceptRoute />
        <ScrollToTop />
        <Routes>
          {/* Product Catalog Page */}

          <Route path="/catalog/" element={<ProductCatalog />}>
            {/* <Route path=":id" element={<ProductCatalog />} /> */}
            {/* <Route path=":id/:subid" element={<ProductCatalog />} /> */}
            <Route path="*" element={<ProductCatalog />} />
          </Route>

          {/* Designers Page */}
          <Route path="/designers/">
            <Route path=":id" element={<Designers />} />
            <Route path="" element={<Designers />} />
          </Route>

          {/* Product Details Page */}
          <Route path="/product/:id" element={<ProductDetails />} />

          {/* Accounts details profile page */}
          <Route path="/accounts/main-profile" element={<AccountsMainPage />} />
          <Route path="/accounts/profile" element={<AccountProfile />} />

          {/* Accounts Order Listing page */}
          <Route path="/accounts/order-history" element={<OrderList />} />

          {/* {Order Details page} */}
          <Route
            path="/accounts/order-view/order_id/:order_id"
            element={<OrderDetailsPage />}
          />

          {/* Accounts Address page */}
          <Route path="/accounts/address" element={<Address />} />

          {/* Accounts Gift-Card page */}
          <Route path="/accounts/gift-card" element={<GiftCardDetails />} />

          {/* Accounts Rewards page */}
          <Route path="/accounts/rewards" element={<Rewards />} />

          {/* Track Your Order pages */}
          <Route path="/track-your-order" element={<TrackOrdersForm />} />

          {/* Wishlist pages */}
          <Route path="/wishlist" element={<WishlistPage />} />

          {/* Friends Wishlist pages */}
          <Route path="/friend-wishlist" element={<FriendWishlistPage />} />

          {/* Account Created thankyou page */}
          <Route path="/account-created" element={<NewsletterThankyouPage />} />

          {/* Checkout Page */}
          <Route exact path="/checkout" element={<Checkout />} />

          {/* Home Page */}
          <Route exact path="/" element={<Home />} />

          {/* CMS pages */}
          <Route exact path="/about-us" element={<AboutUs />} />

          <Route exact path="/contact-us" element={<ContactUsForm />} />

          <Route exact path="/career" element={<Career />} />

          <Route exact path="/faq" element={<Faq />} />

          <Route exact path="/payment-methods" element={<PaymentMethods />} />

          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route
            exact
            path="/return-and-exchange"
            element={<ReturnAndExchange />}
          />

          <Route
            exact
            path="/shipping-information"
            element={<ShippingInformation />}
          />

          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          <Route exact path="/testimonials" element={<Testimonials />} />

          <Route
            exact
            path="/press-and-media/magazine"
            element={<Magazine />}
          />

          <Route exact path="/press-and-media" element={<PressAndRelease />} />

          <Route exact path="/news" element={<News />} />

          {/* Deal Of day Pages */}
          <Route exact path="/dailydeal" element={<DealOfTheDay />} />

          <Route
            exact
            path="/checkout/onepage/ordersuccess/"
            element={<ThankyouPage />}
          />

          {/* Change Payment  page*/}
          <Route
            path="/checkout/onepage/ordersuccess/paymentchanged/"
            element={<ChangePaymentpage />}
          />

          {/* Extra Messages page */}
          <Route path="/extramessages/:order_id" element={<ExtraMessages />} />
          <Route
            path="/extramessages/:order_id/:action"
            element={<ExtraMessages />}
          />

          {/* Payu response component */}
          <Route path="/response/" element={<PayuResponse />} />

          {/* Gift Card Component */}
          <Route path="/gift-card" element={<GiftCardPage />} />

          <Route
            path="/darveys-gift-card/:id"
            element={<GiftCardDetailPage />}
          />

          {/* {Reset Password page} */}
          <Route path="/accounts/resetpassword" element={<ResetPassword />} />

          {/* {Atelier page} */}
          <Route path="/atelier" element={<Atelier />} />
          <Route path="/authentic" element={<Authentic />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:slug" element={<Blog />} />


        {/**
           * BOC
           * Task #157716 Order Not Placed
           * author Maanik Arya
           * date 20-01-2025
           * use_of_code
           * We have created a new route which is used to the redirect Klarna and Affirm payments to a new component called stripe-response.
           * The stripe-response component is responsible for verifying whether the payment was successful.
           * If the payment is successful, the appCreateOrder API is called to place the order, and the user is redirected directly to the "Thank You" page without invoking the appUpdatePaymentStatus or appGetLastOrder APIs.
           * If the payment fails, the customer is redirected back to the payment page to try again.
           * This update addresses and prevents issues related to orders not being captured properly.
           */}
          <Route path="/stripe-response" element={<StripeResponse />} />
          {/* 
            * EOC
          */}


          {/* If no route available, then redirect to home page, we can also use NotFound page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Fragment>
    </>
  );
}

export default Navigation;
